import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-activeText": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            padding: "16px 20px",
            color: "#ffffff",
            position: "relative", // Needed for the absolute positioning of the line

            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "6px", // 6px below the text
              left: "20px",
              right: "20px",
              height: "2px",
              backgroundColor: "#E4DCAD",
            },
          },
          "&.MuiButton-filled": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            width: "fit-content",
            padding: "16px 20px",
            color: "#141414",
            backgroundColor: "#E4DCAD",
            borderRadius: "60px",
            "&:hover": {
              backgroundColor: "#E4DCAD",
            },
          },
          "&.MuiButton-orderNow": {
            fontFamily: "Manrope",
            fontWeight: 700,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            width: "fit-content",
            padding: "16px 20px",
            color: "#F2ECCE",
            backgroundColor: "#DD4744",
            borderRadius: "60px",
            "&:hover": {
              backgroundColor: "#E4DCAD",
            },
          },
          "&.MuiButton-submit": {
            fontFamily: "Manrope",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "19.12px",
            width: "fit-content",
            padding: "18px 26px",
            color: "#FFFFFF",
            backgroundColor: "#0A6FD4",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#0A6FD4",
            },
            "&:disabled": {
              backgroundColor: "#dddddd",
            },
          },
          "&.MuiButton-text": {
            fontFamily: "Manrope",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "-0.5px",
            lineHeight: "19.12px",
            padding: "16px 20px",
            color: "#ffffff",
            position: "relative", // Needed for the absolute positioning of the line
            "&:hover": {
              padding: "16px 20px", // Keep the same padding on hover
              borderRadius: "0px",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "6px", // 6px below the text
                left: "20px",
                right: "20px",
                height: "2px",
                backgroundColor: "#E4DCAD",
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
