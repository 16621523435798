import { ThemeProvider } from "@mui/material";
import Button from "@mui/material/Button";
import theme from "./theme";

const ButtonComponent = ({variant, children, ...props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button sx={{textTransform:'none'}} variant={variant} width='fit-content' {...props}>{children}</Button>
    </ThemeProvider>
  );
};

export default ButtonComponent;
