import React, { useState } from "react";
import SEO from "../components/SEO";
import { styled } from "@mui/material/styles";
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ButtonComponent from "../components/Button";
import JoinUs from "../../public/images/join_us.png";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "#fff", // Label color
  },
  "& .MuiOutlinedInput-root": {
    color: "#fff", // Value color
    "& fieldset": {
      borderColor: "#fff", // Border color
    },
    "&:hover fieldset": {
      borderColor: "#fff", // Border color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff", // Border color when focused
    },
    "& .MuiSvgIcon-root": {
      color: "#fff", // Dropdown icon color
    },
  },
  "& .MuiMenu-paper .MuiMenuItem-root": {
    color: "#131313", // MenuItem text color
  },
}));

const jobOpenings = [
  {
    title: "Creative Designer Intern",
    location: "Remote",
    region: "Remote",
    category: "Creative",
    description:
      "As a Creative Designer Intern, you will work closely with our team to design visually engaging and innovative content for our robotics education platform and other marketing campaigns.",
    responsibilities: [
      "Create branding materials, posters, and social media creatives.",
      "Collaborate with the marketing team to develop unique visual content.",
      "Design engaging UI/UX mockups for our learning platforms.",
      "Support the team with creative ideas for campaigns and events.",
    ],
    requirements: [
      "      Proficiency in design tools like Adobe Photoshop, Figma, or Canva.",
      "A keen eye for detail and aesthetics.",
      "Strong creative thinking and problem-solving skills.",
      "A portfolio of previous design work (preferred).",
    ],
  },
  {
    title: "Video Editor Intern",
    location: "Remote",
    region: "Remote",
    category: "Creative",
    description:
      "Join us as a Video Editor Intern to produce high-quality video content that promotes Unibotix’s robotics initiatives and products.",
    responsibilities: [
      "      Edit and produce videos for social media, promotional content, and tutorials.",
      "      Add animations, transitions, and effects to make videos engaging.",
      "      Collaborate with the marketing and design teams to execute ideas.",
      "      Manage and organize media assets effectively.",
    ],
    requirements: [
      "Experience with video editing softwares",
      "Creativity in storytelling and a strong understanding of video aesthetics.",
      "Ability to meet deadlines and adapt to team feedback.",
      "Understandinng of social media trends",
    ],
  },
  {
    title: "Operations Manager Intern",
    location: "Hybrid",
    region: "Hybrid",
    category: "Operations",
    description:
      "We are looking for an Operations Manager Intern to streamline daily operations and ensure smooth execution of educational programs and events.",
    responsibilities: [
      "      Assist in planning and coordinating robotics workshops and boot camps.",
      "      Monitor inventory and manage logistics for kits and equipment.",
      "      Develop operational strategies to improve efficiency.",
      "      Organizing and managing hirings",
      "      TRack and organize meeting between various departments of the organization",
    ],
    requirements: [
      "      Strong organizational and multitasking abilities.",
      "Basic understanding of business operations and project management.",
      "Proficiency in tools like MS Excel, Google Sheets, or similar.",
      "Excellent communication skills.",
    ],
  },
  {
    title: "Marketing Intern",
    location: "Remote",
    region: "Remote",
    category: "Marketing",
    description:
      "As a Marketing Intern, you will play a key role in spreading the word about Unibotix’s innovative robotics solutions through creative campaigns.",
    responsibilities: [
      "Assist in creating and implementing marketing strategies.",
      "Manage promotional campaigns on social media and other platforms.",
      "Conduct market research to understand audience needs.",
      "Draft and schedule content for online and offline promotions.",
      "Understanding current trends",
    ],
    requirements: [
      "Strong written and verbal communication skills.",
      "Creativity and enthusiasm for engaging audiences.",
      " Knowledge of social media platforms and marketing tools.",
      " A passion for technology and education is a plus.",
    ],
  },
  {
    title: "Embedded Systems Intern",
    location: "Trivandrum",
    region: "Trivandrum",
    category: "Embedded Systems",
    description:
      "The Embedded Systems Intern will contribute to the development of hardware and firmware for our robotics kits and innovative projects.",
    responsibilities: [
      "Develop and test embedded systems for Ubo kits.",
      "Assist in designing and assembling electronic circuits.",
      "Debug firmware and hardware issues.",
      "Document technical processes and contribute to kit enhancements.",
      "Keep the designs clean",
    ],
    requirements: [
      "      Experience with microcontrollers like Arduino or ESP32.",
      "Knowledge of programming languages such as C, C++, or Python.",
      "Familiarity with sensors, motors, and other hardware components.",
      "Problem-solving skills and a passion for robotics.",
    ],
  },
  {
    title: "Social Media Manager Intern",
    location: "Remote",
    region: "Remote",
    category: "Marketing",
    description:
      "The Social Media Manager Intern will craft and execute strategies to grow Unibotix’s online presence and engage with our audience effectively.",
    responsibilities: [
      "Plan and post engaging content on social media platforms.",
      "Analyze social media metrics to track and improve performance.",
      "Interact with the online community and respond to queries.",
      "Brainstorm innovative campaigns to increase brand awareness.",
    ],
    requirements: [
      "      Strong understanding of social media trends and platforms.",
      "Creative thinking and good writing skills.",
      "Basic knowledge of design tools for creating posts (e.g., Canva).",
      "Passion for technology, education, and community building.",
    ],
  },
];

const categories = [
  "All",
  "Embedded Systems",
  "Creative",
  "Marketing",
  "Operations",
];
const regions = ["All", "Remote", "Hybrid", "Trivandrum"];

const Careers = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedRegion, setSelectedRegion] = useState("All");
  const [pendingCategory, setPendingCategory] = useState("All");
  const [pendingRegion, setPendingRegion] = useState("All");
  const [selectedJob, setSelectedJob] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleSendResume = (jobTitle) => {
    const subject = encodeURIComponent(`Application for ${jobTitle}`);
    window.location.href = `mailto:careers@unibotix.com?subject=${subject}`;
  };

  const handleKnowMore = (job) => {
    setSelectedJob(job);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedJob(null);
  };

  const handleFilter = () => {
    setSelectedCategory(pendingCategory);
    setSelectedRegion(pendingRegion);
  };

  const handleReset = () => {
    setSelectedCategory("All");
    setSelectedRegion("All");
    setPendingCategory("All");
    setPendingRegion("All");
  };

  const filteredJobs = jobOpenings.filter((job) => {
    const categoryMatch =
      selectedCategory === "All" || job.category === selectedCategory;
    const regionMatch =
      selectedRegion === "All" || job.region === selectedRegion;
    return categoryMatch && regionMatch;
  });

  return (
    <>
      <SEO 
        title="Careers at Unibotix Innovations | Join Our Team"
        description="Join Unibotix Innovations and be part of an innovative team working on cutting-edge robotics and automation solutions. Explore exciting career opportunities in embedded systems, creative design, marketing, and operations."
        keywords="careers, jobs, robotics jobs, automation careers, technology careers, internships, Unibotix careers, robotics careers"
        url="https://unibotix.io/careers"
      />
      <Box
        sx={{
          minHeight: "calc(100vh - 100px)",
          pt: { xs: 10, md: 15 },
          px: "20px",
          pb: "100px",
          marginTop: { xs: "0px", md: "100px" },
          bgcolor: "rgba(12,129,246,1)",
          "&::-webkit-scrollbar": {
            width: "3px",
            marginLeft: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#333",
            borderRadius: "1.5px",
          },
          "&::-webkit-scrollbar-track": {
            margin: "10px 0",
            background: "transparent",
          },
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          gap="100px"
          justifyContent="center"
          alignItems={{ xs: "center", lg: "end" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", lg: "start" },
            }}
          >
            <Box
              component="img"
              src={JoinUs}
              sx={{
                marginTop: { xs: "10px", lg: "-400px" },
                width: { xs: "300px", md: "550px" },
                alignSelf: "center",
                marginBottom:"-40px"
              }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={{ xs: "center", lg: "start" }}
            >
              <Typography
                component="h1"
                sx={{
                  // mb: 4,
                  fontSize: "72px",
                  fontWeight: "bold",
                  fontFamily: "Manrope",
                  color: "#ffffff",
                }}
              >
                Join Us
              </Typography>
              <Typography
                sx={{
                  fontWeight: "400",
                  fontFamily: "Manrope",
                  color: "#ffffff",
                  fontSize: "14px",
                  maxWidth:{xs:"350px", md:"550px"},
                  mb:"15px",
                  textAlign:{xs:"center", md:"left"}
                }}
              >
                We at Unibotix values loyalty passion and hardwork above all else.  We are a team of like minded people with the vision of growth and if you feel you would be a good fit for our team share your resume and join us on the ride.
              </Typography>{" "}
            </Box>
          </Box>
          <Box>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} md={4}>
                <StyledFormControl fullWidth>
                  <InputLabel sx={{ color: "#fff" }}>Category</InputLabel>
                  <Select
                    value={pendingCategory}
                    label="Category"
                    onChange={(e) => setPendingCategory(e.target.value)}
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledFormControl fullWidth>
                  <InputLabel>Region</InputLabel>
                  <Select
                    value={pendingRegion}
                    label="Region"
                    onChange={(e) => setPendingRegion(e.target.value)}
                  >
                    {regions.map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: "flex", gap: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleFilter}
                  sx={{
                    bgcolor: "#fff",
                    color: "rgba(12,129,246,1)",
                    fontWeight: 800,
                    "&:hover": { bgcolor: "#333", color: "#fff" },
                  }}
                >
                  Filter
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleReset}
                  sx={{
                    color: "#fff",
                    borderColor: "#fff",
                    fontWeight: 800,

                    "&:hover": {
                      borderColor: "#333",
                      color: "#333",
                    },
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>

            <Stack
              spacing={2}
              maxHeight={{ xs: "fit-content", md: "520px" }}
              minHeight={{ xs: "fit-content", md: "520px" }}
              sx={{
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                  // marginLeft: "10px"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#bbb",
                  borderRadius: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  // margin: "10px 0",
                  background: "transparent",
                  marginRight: "10px",
                },
                paddingRight: { xs: "0px", lg: "20px" },
              }}
            >
              {filteredJobs.map((job, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 3,
                    border: "1px solid rgba(12,129,246,1)",
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    gap: { xs: 5, md: 30 },
                    bgcolor: "#fff",
                    minWidth: { xs: "fit-content", md: "800px" },
                  }}
                >
                  <Box sx={{ flex: 1, minWidth: { xs: "100%", md: "auto" } }}>
                    <Typography
                      variant="h6"
                      component="h2"
                      sx={{ color: "#131313", mb: 1 }}
                    >
                      {job.title}
                    </Typography>
                    <Typography variant="body1" color="rgba(12,129,246,1)">
                      {job.location}
                    </Typography>
                  </Box>
                  <Stack
                    direction={{ xs: "row", sm: "row" }}
                    spacing={2}
                    sx={{
                      minWidth: { xs: "100%", md: "auto" },
                      justifyContent: { xs: "stretch", md: "flex-end" },
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleKnowMore(job)}
                      sx={{
                        color: "#131313",
                        border: "1.5px solid #131313",
                        borderRadius:"60px",
                        fontWeight:500,
                        "&:hover": {
                          borderColor: "#333",
                          backgroundColor: "#131313",
                        },
                      }}
                    >
                      Know More
                    </Button>
                    <ButtonComponent
                      variant="filled"
                      onClick={() => handleSendResume(job.title)}
                      sx={{
                        bgcolor: "#333",
                        "&:hover": { bgcolor: "#333" },
                      }}
                    >
                      Send Resume
                    </ButtonComponent>{" "}
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>

        <Dialog
          open={modalOpen}
          onClose={handleCloseModal}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 2,
              maxHeight: "90vh",
              "&::-webkit-scrollbar": {
                width: "3px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#333",
                borderRadius: "1.5px",
              },
            },
          }}
        >
          {selectedJob && (
            <>
              <DialogTitle sx={{ pr: 6, bgcolor: "#f5f5f5" }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  {selectedJob.title}
                </Typography>
                <IconButton
                  onClick={handleCloseModal}
                  sx={{
                    position: "absolute",
                    right: 16,
                    top: 16,
                    color: "#666",
                    "&:hover": {
                      color: "#fff",
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent sx={{ py: 3 }}>
                <Stack spacing={3} marginTop="20px">
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Location
                    </Typography>
                    <Typography variant="body1">
                      {selectedJob.location}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Description
                    </Typography>
                    <Typography variant="body1">
                      {selectedJob.description}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Responsibilities
                    </Typography>
                    <Stack component="ul" spacing={1} sx={{ pl: 2 }}>
                      {selectedJob.responsibilities.map(
                        (responsibility, index) => (
                          <Typography
                            component="li"
                            key={index}
                            variant="body1"
                          >
                            {responsibility}
                          </Typography>
                        )
                      )}
                    </Stack>
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      gutterBottom
                    >
                      Requirements
                    </Typography>
                    <Stack component="ul" spacing={1} sx={{ pl: 2 }}>
                      {selectedJob.requirements.map((requirement, index) => (
                        <Typography component="li" key={index} variant="body1">
                          {requirement}
                        </Typography>
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSendResume(selectedJob.title);
                    handleCloseModal();
                  }}
                  sx={{
                    bgcolor: "#131313",
                    color: "#fff",
                    "&:hover": { bgcolor: "#666" },
                  }}
                >
                  Send Resume
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default Careers;
