import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Stack } from "@mui/material";
import { lazy, Suspense, useEffect, useState } from "react";
import Careers from "./pages/Careers";

const Navbar = lazy(() => import("./components/Navbar"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const AboutUbo = lazy(() => import("./pages/AboutUbo"));
const OurServices = lazy(() => import("./pages/OurServices"));
const HappyClients = lazy(() => import("./pages/HappyClients"));
const GetInTouch = lazy(() => import("./pages/GetInTouch"));
const Footer = lazy(() => import("./pages/Footer"));
const Download = lazy(() => import("./pages/Downloads"));

function App() {
  const [activeSection, setActiveSection] = useState("home");
  const [showNavbar, setShowNavbar] = useState(true);

  const sections = [
    { id: "home", title: "Home" },
    { id: "about", title: "About us" },
    { id: "products", title: "Products" },
    { id: "services", title: "Our Services" },
    { id: "careers", title: "Careers" },
    { id: "contact", title: "Contact us" },
  ];

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setShowNavbar(scrollTop < 200);

    // Only track active section on home page
    if (window.location.pathname === '/') {
      const sectionPositions = sections.map((section) => {
        const element = document.getElementById(section.id);
        if (!element) return null;
        return {
          id: section.id,
          offsetTop: element.offsetTop,
          offsetHeight: element.offsetHeight,
        };
      }).filter(Boolean);

    const active = sectionPositions.find(
      (section) =>
        scrollTop >= section.offsetTop - 50 &&
        scrollTop < section.offsetTop + section.offsetHeight - 50
    );

    if (active) {
      setActiveSection(active.id);
      // Update URL hash without triggering scroll
      window.history.replaceState(null, '', `/#${active.id}`);
    }
    }
  };

  const handleMouseMove = (event) => {
    if (window.scrollY < 200 || event.clientY < 100) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <Stack alignItems="center" justifyContent="center" height="100vh">
            <img src="/images/loading.gif" style={{ height: "400px", width: "400px" }} />
          </Stack>
        }
      >
        <Stack maxWidth="100vw" overflowX="hidden">
          {showNavbar && <Navbar sections={sections} activeSection={activeSection} setActiveSection={setActiveSection}/>}
          <Routes>
            <Route path="/" element={
              <>
                <Home id="home" />
                <About id="about" />
                <AboutUbo id="products" />
                <OurServices id="services" />
                <HappyClients id="services" />
                <GetInTouch id="contact" />
                <Footer />
              </>
            } />
            <Route path="/download" element={<Download />} />
            <Route path="/careers" element={<Careers />} />
          </Routes>
        </Stack>
      </Suspense>
    </Router>
  );
}

export default App;
